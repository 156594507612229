<template>
	<Drawer
		v-model:visible="isActive"
		position="right"
		class="usersmmu-add-dialog"
		header="Add User">
		<ApiTable
			v-model:selection="selected"
			selection-mode="multiple"
			flat
			:list-state="listState">
			<Column
				selection-mode="multiple"
				header-style="width: 3rem"></Column>
			<Column
				field="name"
				header="Name" />
		</ApiTable>
		<Button
			class="usersmmu-add-dialog__add-button"
			:disabled="selected.length === 0"
			:loading="isLoading"
			:label="`Add ${selected.length} usersmmu`"
			icon="fal fa-plus"
			@click="submit" />
	</Drawer>
</template>

<script setup lang="ts">
import Drawer from 'primevue/drawer'
import Button from 'primevue/button'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import type { Test } from '@/models/Test/Model'
import { defineModel, ref, watch } from 'vue'
import type { User } from '@/models/User/Model'
import { useUserListState } from '@/models/User/States'
import TestsApi from '@/models/Test/Api'

const emit = defineEmits(['update'])

const isActive = defineModel<boolean>()

const props = defineProps<{
	testId: Test['id']
}>()

const selected = ref<User[]>([])
const isLoading = ref(false)

const listState = useUserListState()
listState.defaultParams = {
	notFromRelation: {
		model: 'App\\Models\\Test',
		id: props.testId,
		relation: 'usersmmu',
	},
}

watch(
	isActive,
	() => {
		if (!isActive.value) return
		isLoading.value = true
		listState.getList()
		isLoading.value = false
		selected.value = []
	},
	{ immediate: true },
)

async function submit() {
	isLoading.value = true
	try {
		await new TestsApi().updateRelation(props.testId, 'usersmmu', {
			method: 'syncWithoutDetaching',
			params: selected.value.map((item) => item.id),
		})
		isActive.value = false
		emit('update')
		selected.value = []
	} finally {
		isLoading.value = false
	}
}
</script>

<style lang="scss">
.usersmmu-add-dialog {
	width: 800px !important;

	.p-drawer-content {
		gap: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px 0;
	}

	.usersmmu-add-dialog__add-button {
		margin: 0 20px 0;
		min-height: 39px;
	}
}
</style>
