<template>
	<Card class="testsomu">
		<template #title>
			<h4 class="testsomu__title">Testsomu</h4>
			<Button
				icon="fal fa-link"
				label="Connect"
				@click="isAddDialogActive = true" />
			<Button
				icon="fal fa-plus"
				label="Create"
				@click="isFormActive = true" />
		</template>
		<template #content>
			<ApiTable
				flat
				:list-state="listState"
				@row-click="openDetails">
				<Column
					field="title"
					header="Title" />
				<Column header="">
					<template #body="slotProps">
						<Button
							severity="secondary"
							outlined
							rounded
							icon="fal fa-times"
							:loading="dissociateLoading === slotProps.data.id"
							@click.stop.prevent="detatch(slotProps.data)" />
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
	<TestsomuAddDialog
		v-model="isAddDialogActive"
		:user-id="props.userId"
		@update="refresh()" />
	<TestForm
		:as-dialog="true"
		:visible="isFormActive"
		:should-redirect="false"
		:force-values="{
			useromu_id: Number(props.userId),
		}"
		:hide-inputs="['useromu_id']"
		@close="isFormActive = false"
		@created="refresh()" />
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import TestsomuAddDialog from './TestsomuAddDialog.vue'
import TestForm from '@/views/Test/components/Form.vue'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

import { useTestListState } from '@/models/Test/States'
import type { Test } from '@/models/Test/Model'
import type { User } from '@/models/User/Model'
import UsersApi from '@/models/User/Api'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	userId: User['id']
}>()

const router = useRouter()
const isAddDialogActive = ref(false)
const isFormActive = ref(false)
const dissociateLoading = ref(null as null | number | string)
const listLoading = ref(false)
const listState = useTestListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\User',
		id: props.userId,
		relation: 'testsomu',
	},
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

async function detatch(item: Test) {
	dissociateLoading.value = item.id
	emit('start-loading')
	await new UsersApi().updateRelation(props.userId, 'testsomu', {
		method: 'dissociate',
		params: [item.id],
	})
	dissociateLoading.value = null
	await refresh()
	emit('stop-loading')
}

function openDetails(item: { data: Test }) {
	router.push({ name: 'tests-edit', params: { id: item.data.id } })
}
</script>

<style lang="scss" scoped>
.testsomu {
	width: 100%;
	max-width: 600px;
	overflow: hidden;

	:deep(.p-card-body) {
		padding: 20px 0 0;

		.p-card-caption {
			padding: 0px 20px 12px;

			.p-card-title {
				display: flex;
				align-items: center;
				gap: 10px;

				.testsomu__title {
					flex: 1;
					text-align: left;
				}
			}
		}
	}
}
</style>
