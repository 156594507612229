import { createRouter, createWebHistory } from 'vue-router'
import authGuard from './guards/authGuard'
import { useAuthStore } from '@/stores/Auth'

import Login from '../views/Auth/Login.vue'
import AdminApi from '@/helpers/api/AdminApi'
import UserList from '../views/User/List.vue'
import UserEdit from '../views/User/Edit.vue'
import TestList from '../views/Test/List.vue'
import TestEdit from '../views/Test/Edit.vue'
/* GENERATOR(IMPORT) */

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			redirect: '/users',
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			beforeEnter: () => {
				const auth = useAuthStore()
				if (auth.user) {
					return { path: auth.lastRoute ?? '/posts' }
				}
			},
		},
		{
			path: '/logout',
			component: Login,
			beforeEnter: async () => {
				const auth = useAuthStore()
				await auth.logout()
				return { path: '/login' }
			},
		},
		{
			path: '/clear-data',
			component: Login,
			beforeEnter: async () => {
				await AdminApi.clearData()
				window.location.reload()
				throw new Error('Clear data')
			},
		},
		{
			path: '/users',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'users-list',
					component: UserList,
				},
				{
					path: '/users/create',
					name: 'users-create',
					component: UserEdit,
				},
				{
					path: '/users/:id',
					name: 'users-edit',
					component: UserEdit,
				},
			],
		},
		{
			path: '/tests',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'tests-list',
					component: TestList,
				},
				{
					path: '/tests/create',
					name: 'tests-create',
					component: TestEdit,
				},
				{
					path: '/tests/:id',
					name: 'tests-edit',
					component: TestEdit,
				},
			],
		},
		/* GENERATOR(ROUTES) */
		{
			path: '/:pathMatch(.*)*',
			redirect: '/login',
		},
	],
})

export default router
