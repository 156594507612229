<template>
	<component
		:is="asDialog ? Dialog : Card"
		:visible="visible"
		border
		modal
		class="form"
		:header="props.isEdit ? 'Update Test' : 'Create Test'"
		@update:visible="emit('close')">
		<template
			v-if="!asDialog"
			#title>
			{{ props.isEdit ? 'Update Test' : 'Create Test' }}
		</template>
		<template #[contentSlot]>
			<form @submit.prevent="submit">
				<div
					v-if="!props.hideInputs.includes('title')"
					class="form__input-container">
					<label :for="`${uuid}-title`">Title</label>
					<InputText
						:id="`${uuid}-title`"
						v-model="form.title"
						:disabled="!!props.forceValues.title"
						:class="{ 'form__input--error': formErrors.title }" />
					<small
						v-if="formErrors.title"
						class="form__input-error-message"
						>{{ formErrors.title }}</small
					>
				</div>
				<div
					v-if="!props.hideInputs.includes('description')"
					class="form__input-container">
					<label :for="`${uuid}-description`">Description</label>
					<InputText
						:id="`${uuid}-description`"
						v-model="form.description"
						:disabled="!!props.forceValues.description"
						:class="{ 'form__input--error': formErrors.description }" />
					<small
						v-if="formErrors.description"
						class="form__input-error-message"
						>{{ formErrors.description }}</small
					>
				</div>
				<div
					v-if="!props.hideInputs.includes('useromu_id')"
					class="form__input-container">
					<label :for="`${uuid}-useromu_id`">Useromu</label>
					<ModelSelect
						:id="`${uuid}-useromu_id`"
						v-model="form.useromu_id"
						:api="new UsersApi()"
						:disabled="!!props.forceValues.useromu_id"
						:class="{ 'form__input--error': formErrors.useromu_id }"
						option-label="name" />
					<small
						v-if="formErrors.useromu_id"
						class="form__input-error-message"
						>{{ formErrors.useromu_id }}</small
					>
				</div>
				<div
					v-if="!props.hideInputs.includes('useroou_id')"
					class="form__input-container">
					<label :for="`${uuid}-useroou_id`">Useroou</label>
					<ModelSelect
						:id="`${uuid}-useroou_id`"
						v-model="form.useroou_id"
						:api="new UsersApi()"
						:disabled="!!props.forceValues.useroou_id"
						:class="{ 'form__input--error': formErrors.useroou_id }"
						option-label="name" />
					<small
						v-if="formErrors.useroou_id"
						class="form__input-error-message"
						>{{ formErrors.useroou_id }}</small
					>
				</div>
				<Button
					:loading="loading"
					icon="fal fa-save"
					:label="isEdit ? 'Update' : 'Create'"
					type="submit"
					@submit.prevent="submit" />
			</form>
		</template>
	</component>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount, toRaw, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import TestsApi from '@/models/Test/Api'
import type { Test } from '@/models/Test/Model'
import Card from 'primevue/card'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import ModelSelect from '@/components/ModelSelect.vue'
import UsersApi from '@/models/User/Api'
import InputText from 'primevue/inputtext'

const emit = defineEmits(['start-loading', 'stop-loading', 'close', 'created', 'updated'])
const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: Test['id']
		hideInputs?: (keyof (typeof form)['value'])[]
		defaultValues?: Partial<(typeof form)['value']>
		forceValues?: Partial<(typeof form)['value']>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
	},
)

const instance = getCurrentInstance()
const uuid = ref(instance!.uid)
const router = useRouter()
const form = ref(getDefaultForm())
const loading = ref(false)
const formErrors = ref<Record<string, string>>({})

const contentSlot = computed(() => (props.asDialog ? 'default' : 'content'))

watch(loading, (val: boolean) => {
	if (val) emit('start-loading')
	else emit('stop-loading')
})

onBeforeMount(reset)

async function reset() {
	if (props.defaultValues) {
		populateForm(props.defaultValues)
	}
	if (props.isEdit) {
		loading.value = true
		populateForm(await getDetails())
	}
}

function getDefaultForm() {
	return {
		title: '',
		description: '',
		useromu_id: 0,
		useroou_id: 0,
	}
}

async function getDetails() {
	loading.value = true
	let res = await new TestsApi().show(props.id!)
	loading.value = false
	return res.data
}

function populateForm(entity: Partial<(typeof form)['value']>) {
	Object.assign(form.value, { ...getDefaultForm(), ...entity })
}

async function create() {
	formErrors.value = {}
	loading.value = true
	try {
		let params = structuredClone(toRaw(form.value))
		Object.assign(params, props.forceValues ?? {})
		let res = await new TestsApi().store(params)
		await attachToAll(res.data.id)
		emit('created')
		return res.data
	} catch (error: any) {
		if (error.response?.status === 422 && error.response?.data?.errors) {
			Object.entries(error.response?.data?.errors).forEach(([key, value]) => {
				formErrors.value[key] = (value as string[])[0]
			})
		}
		throw error
	} finally {
		loading.value = false
	}
}

async function update() {
	formErrors.value = {}
	loading.value = true
	try {
		let params = structuredClone(toRaw(form.value))
		Object.assign(params, props.forceValues ?? {})
		let res = await new TestsApi().update(props.id!, params)
		await attachToAll(res.data.id)
		emit('updated')
		return res.data
	} catch (error: any) {
		if (error.response?.status === 422 && error.response?.data?.errors) {
			Object.entries(error.response?.data?.errors).forEach(([key, value]) => {
				formErrors.value[key] = (value as string[])[0]
			})
		}
		throw error
	} finally {
		loading.value = false
	}
}

async function attachToAll(id: string | number) {
	if (!props.attachTo) return
	for (let [key, value] of Object.entries(props.attachTo)) {
		loading.value = true
		try {
			await new TestsApi().updateRelation(id, key, {
				method: value.method,
				params: [value.id],
			})
		} finally {
			loading.value = false
		}
	}
}

async function submit() {
	if (props.isEdit && props.id) {
		update()
	} else {
		let entity = await create()
		if (props.shouldRedirect) {
			router.push({ name: 'tests-edit', params: { id: entity!.id } })
		}
	}
	emit('close')
}
</script>

<style lang="scss">
.form {
	width: 100%;
	max-width: 600px;

	.form__title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__input-container {
			display: flex;
			flex-direction: column;
			gap: 5px;
			margin-bottom: 10px;

			.form__input-error-message {
				color: var(--p-red-500);
			}

			.form__input--error {
				border-color: var(--p-red-500);
			}
		}
	}
}
</style>
