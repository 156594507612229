import type { Test } from '@/models/Test/Model'
import TestsApi from '@/models/Test/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class TestDetailsState extends DetailsState<TestsApi, Test> {
	api = new TestsApi()
}

export function useTestDetailsState() {
	return new TestDetailsState()
}

export class TestListState extends ListState<TestsApi, Test, LaravelPaginationResponse<Test>> {
	api = new TestsApi()
}

export function useTestListState() {
	return new TestListState()
}
