<template>
	<Header :title="isEdit ? 'Edit User' : 'Create User'" />
	<ProgressBar
		v-if="loaders.size > 0"
		mode="indeterminate"
		class="edit__progress-bar" />
	<Container class="edit">
		<Form
			:id="route.params.id as string"
			:is-edit="isEdit"
			@start-loading="loaders.add('form')"
			@stop-loading="loaders.delete('form')" />
		<Testsmmt
			v-if="isEdit"
			:user-id="route.params.id as string"
			@start-loading="loaders.add('Test')"
			@stop-loading="loaders.delete('Test')" />
		<Testsomu
			v-if="isEdit"
			:user-id="route.params.id as string"
			@start-loading="loaders.add('Test')"
			@stop-loading="loaders.delete('Test')" />
		<Testsmmu
			v-if="isEdit"
			:user-id="route.params.id as string"
			@start-loading="loaders.add('Test')"
			@stop-loading="loaders.delete('Test')" />
	</Container>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import Header from './components/Header.vue'
import Form from './components/Form.vue'
import ProgressBar from 'primevue/progressbar'
import Container from '@/components/Container.vue'
import Testsmmt from './components/Testsmmt.vue'
import Testsomu from './components/Testsomu.vue'
import Testsmmu from './components/Testsmmu.vue'

const route = useRoute()
const isEdit = computed(() => route.name === 'users-edit')
const loaders = reactive(new Set<string>())
</script>

<style lang="scss" scoped>
.edit__progress-bar {
	height: 4px;
	margin-bottom: -4px;
	width: 100%;
	border-radius: 0;
}

.edit {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	gap: 20px;
	justify-items: center;
	align-items: start;
}
</style>
