import type { Test, TestStorePayload, TestUpdatePayload } from '@/models/Test/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class TestsApi extends Api<
	Test,
	LaravelPaginationResponse<Test>,
	TestStorePayload,
	TestUpdatePayload
> {
	route = 'tests'
}
